const AdminMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Accounts',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.accounts'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Users',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.users'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-admin-line',
    active_icon: 'ri-admin-fill'
  },
  {
    title: 'Employees',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.employees'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-user-line',
    active_icon: 'ri-user-fill'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Banks',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.maintenances.banks'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-bank-line',
    active_icon: 'ri-bank-fill'
  }
]

export default AdminMenu
