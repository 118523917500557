const TAMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'ta.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Tax Codes',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.tax-codes'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-qr-code-line',
    active_icon: 'ri-qr-code-fill'
  },
  {
    title: 'Document Types',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.document-types'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-copy-line',
    active_icon: 'ri-file-copy-fill'
  },
  {
    title: 'Payment Natures',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.payment-natures'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-currency-line',
    active_icon: 'ri-currency-fill'
  },
  {
    title: 'Business Partners',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.maintenances.business-partners'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-group-line',
    active_icon: 'ri-group-fill'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'sidebar.ta.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'QA of Payees',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.reports.qap'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-excel-line',
    active_icon: 'ri-file-excel-fill'
  },
  {
    title: 'Vat Details',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ta.reports.vat-details'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-excel-line',
    active_icon: 'ri-file-excel-fill'
  }
]

export default TAMenu
