const UserMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Payment Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.expenses.payment-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-line',
    active_icon: 'ri-file-fill'
  },
  {
    title: 'Request Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.request-approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-edit-line',
    active_icon: 'ri-file-edit-fill'
  }
]

export default UserMenu
