const BOMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'bo.maintenances'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Fiscal Years',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.fiscal-years'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-calendar-2-line',
    active_icon: 'ri-calendar-2-fill'
  },
  {
    title: 'Budget Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.budget-centers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-briefcase-2-line',
    active_icon: 'ri-briefcase-2-fill'
  },
  {
    title: 'Items',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-briefcase-3-line',
    active_icon: 'ri-briefcase-3-fill'
  },
  {
    title: 'Item Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'bo.maintenances.item-centers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-briefcase-5-line',
    active_icon: 'ri-briefcase-5-fill'
  }
]

export default BOMenu
